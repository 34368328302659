<template>
  <b-card class="w-50 m-auto">
    <div
      v-if="!verificationMessage"
      class="d-flex"
    >
      <b-spinner />
      <h5 class="ml-2">
        Verifying account deposit ...
      </h5>
    </div>

    <div
      v-else
      class="text-center"
    >
      <div class="d-flex justify-content-center align-items-center">
        <feather-icon
          icon="CheckCircleIcon"
          size="48"
          color="#28c76f"
        />
        <h3
          v-if="verificationMessage.toLowerCase() === 'verified'"
          class="ml-1"
        >
          Your funds have been deposited into your account!
        </h3>
      </div>
      <p class="mt-4">
        Redirecting back to your accounts ...
      </p>
    </div>
  </b-card>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import { BCard, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BSpinner,
  },
  data() {
    return {
      refId: this.$route.query.refId,
      verificationMessage: null,
    }
  },
  mounted() {
    this.verifyDeposit()
  },
  methods: {
    verifyDeposit() {
      useApollo.company.verifyCompanyWalletDepositByStripe({
        companyUid: this.$store.state.project.selectedCompany,
        companyProduct: 'STRIPEDEPOSIT',
        input: {
          refId: this.refId,
        },
      }).then(response => {
        this.verificationMessage = response.data.verifyCompanyWalletDepositByStripe.message
      }).finally(() => {
        setTimeout(() => {
          this.$router.push({ name: 'wallet' })
        }, 1000)
      })
    },
  },
}
</script>
